import React, { useState } from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
  {
    id: 1,
    title: "Master Degree of Data Science",
    years: "2020 - Present",
    content:
      "My Master's in Data Science at Burapha University, was an immersive journey into advanced statistical techniques, machine learning, and big data. Hands-on projects with real-world datasets honed my analytical skills. Exposure to diverse industries broadened my understanding, and collaboration in hackathons refined my ability to create data-driven solutions. The program's interdisciplinary nature enhanced teamwork and communication skills. Completing this degree not only solidified my expertise but also instilled a mindset of continuous learning, empowering me to navigate the dynamic field of data science with confidence.",
  },
  {
    id: 2,
    title: "Bachelor’s Degree of Computer Science",
    years: "2015 - 2018",
    content:
      "During my Computer Science studies at Burapha University, I mastered algorithms, data structures, and software engineering. Engaging in hands-on projects and industry internships honed my practical skills and provided valuable insights. Collaborative learning and participation in coding competitions nurtured my passion for innovation. This degree not only equipped me with technical proficiency but also emphasized critical thinking and effective communication, setting a solid foundation for success in the technology field.",
  },
];

const experienceData = [
  {
    id: 1,
    title: "MonkeyEveryday Co., Ltd.",
    position: "(Back-end Developer)",
    years: "Sep 2023 - Present",
    content: `• Learning Management System (NestJS, Django, redis, RESTful, GraphQL )`,
  },
  {
    id: 2,
    title: "Conicle Co., Ltd.",
    position: "(Back-end Developer)",
    years: "May 2022 - Aug 2023",
    content: `Scrum Team & Manage Team: 
      • Execute according to the project plan Modify and modifythe plan as appropriate to meet your needs. and requirements of the product owner.
      • Implement and manage projects to achieve targets and deliver projects on time.
      • Supervise and communicate with the team to understand the same about the work that needs to be done. and delivered each day.
      • Maintain documentation and ensure the completeness of project documentation. Up to date and store document files appropriately.
      • Liaise with product owner and team members to keep updates on project progress.

      • Learning Management System (NestJS, Django, rabbitmq )
      • People experience platform (NestJS, Django, rabbitmq )`,
  },
  {
    id: 3,
    title: "Greenline Synergy Co., Ltd.",
    position: "(Software Developer)",
    years: "Jun 2021 - Apr 2022",
    content: `• CRM System - (JQuery, Django) \n• Line OA - (JQuery, Django) \n• Insurance - (JQuery, Django)
`,
  },
  {
    id: 4,
    title: "Computerlogy Co., Ltd.",
    position: "(Business Analyst)",
    years: "Sep 2020 - May2021",
    content: `• Business Analyst CRM System \n• System Analyst CRM System`,
  },
  {
    id: 5,
    title: "Chaos Theory Co., Ltd.",
    position: "(Back-End Developer)",
    years: "Sep 2019 - Jul 2020 ",
    content: `• Web Scraping (Python, Flask, Django)
• Flask-RESTful API (Python, Flask, Django)
• Flask (Python, Flask, Django)
• Dii Aesthetic (Templatepower PHP)`,
  },
  {
    id: 6,
    title: "Freelance Web Development",
    position: "(Full Stack Developer)",
    years: "Jan 2015 - Present ",
    content: `• Planista (Financial Statement)(Swift)
• Solario Serviced Apartment Management System (Python, Flask, mongoDB)
• Classify Birds by Their Bones (AI)(HTML CSS JS)
• Program Calculate the distance of the stars(Python)
• Examination program (Image Processing)(Python)
• PPOS The Cultivation System(C/C++, IOT)
• Search Pure Engine (Search Engine)(Python, HTML, CSS, JS)
• Cookly : Cooking app for travel learning (PHP)
• Snake & Ladder Game (Swift)
• Hotel Management System (PHP)
• Pagman Game (C++)
• Farming Game (C#, Unity3D)
    `,
  },
];

function Experiences() {
  const [rightTimeline, setRightTimeline] = useState(false);
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Experience" />
        <div className="row">
          <div className="col-md-6">
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>

          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div
              className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden"
              style={{
                height: rightTimeline ? "auto" : "800px",
                overflow: "hidden",
              }}
            >
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
            <div className="load-more text-center mt-4">
              <button
                className="btn btn-default"
                onClick={() => {
                  setRightTimeline((prev) => !prev);
                }}
              >
                <span>
                  <i className="fas fa-spinner"></i> Load more
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
