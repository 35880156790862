import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage3 from "./pages/Homepage3";
// import Homepage3Light from "./pages/Homepage3Light";
import "./App.scss";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage3 />} />
      </Routes>
      {/* <Routes>
        <Route path="/a" element={<Homepage3Light />} />
      </Routes> */}
    </BrowserRouter>
  );
}

export default App;
