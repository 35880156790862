import React from "react";

function Skill({ progress, isVisible }) {
  const { title, percantage: rawPercentData, progressColor } = progress;

  // BASIC 0 - 33 , INTERMEDIATE 34-66, ADVANCED 67-100

  const percantage = (() => {
    if (rawPercentData >= 0 && rawPercentData <= 33) {
      return { value: 16, text: "Basic" };
    } else if (rawPercentData > 33 && rawPercentData <= 66) {
      return { value: 45, text: "Intermediate" };
    } else if (rawPercentData > 66 && rawPercentData <= 100) {
      return { value: 90, text: "Advanced" };
    }
  })();

  const winWidth = window.innerWidth;
  const progressQuery = () => {
    if (winWidth && winWidth > 767) {
      return (
        <div
          className="progress-bar data-background"
          style={
            isVisible
              ? {
                  width: `${percantage.value}%`,
                  background: progressColor,
                }
              : { width: 0, background: progressColor }
          }
        ></div>
      );
    }
    return (
      <div
        className="progress-bar data-background"
        style={{
          width: `${percantage.value}%`,
          background: progressColor,
        }}
      ></div>
    );
  };
  return (
    <div className="skill-item">
      <div className="skill-info clearfix">
        <h4 className="float-left mb-3 mt-0">{title}</h4>
        {/* <span className="float-right">{percantage.text}</span> */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "-16px",
            opacity: "0.65",
            fontSize: "14px",
          }}
        >
          <p
            style={{
              opacity:
                percantage.text === "Basic" ||
                percantage.text === "Intermediate" ||
                percantage.text === "Advanced"
                  ? "1"
                  : "0.25",
            }}
          >
            Basic
          </p>
          <p
            style={{
              opacity:
                percantage.text === "Intermediate" ||
                percantage.text === "Advanced"
                  ? "1"
                  : "0.25",
            }}
          >
            Intermediate
          </p>
          <p
            style={{
              opacity: percantage.text === "Advanced" ? "1" : "0.25",
            }}
          >
            Advanced
          </p>
        </div>
      </div>
      <div className="progress">{progressQuery()}</div>
    </div>
  );
}

export default Skill;
